import React, { useEffect, useState } from 'react';
import { GetRecipesActions, SelectRecipeCard, SelectedRecipeCard } from '@mealhaus/recipes';
import { useSelector, useDispatch } from "react-redux";
import { GlobalAppState } from "@goodfood/store";
import { CreateMealPlanSubscriptionSubscriptionDto } from '@mealhaus/meal-plan-subscription';
import { CreateOrderActions } from '@mealhaus/orders';
import moment from "moment"
import { DATE_FORMAT } from "@mealhaus/constants";

interface Props {
    week: string;
    subscription: CreateMealPlanSubscriptionSubscriptionDto
}
export interface BoxItem {
    id: string;
    quantity: number
}

export const SelectRecipes = ({ week, subscription }: Props) => {
    const recipes = useSelector(
        (state: GlobalAppState) => state.getRecipes.recipes
    );

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetRecipesActions.getRecipes());
    }, []);

    const [box, setBox] = useState<{ [x: string]: BoxItem }>({})

    useEffect(() => {
        setBox({})
    }, [week,subscription]);

    const weekOrder = subscription.orders.find(order=>moment(week).format(DATE_FORMAT) === moment(order.weekStartDate).format(DATE_FORMAT))

    const orderRecipes = Object.values(box)

    const numberOfRecipesInBox = orderRecipes.reduce((acc,orderRecipe)=>acc+orderRecipe.quantity,0)

    const addRecipeToBox = (id: string) => {
        if (numberOfRecipesInBox === subscription.numberOfRecipes) {
            return
        }
        const boxItem = box[id]
        if (!!boxItem) {
            setBox({ ...box, [id]: { ...boxItem, quantity: boxItem.quantity + 1 } })
        }
        else {
            setBox({ ...box, [id]: { id, quantity: 1 } })
        }
    }

    const removeRecipeFromBox = (id: string) => {
        const boxItem = box[id]

        if (!!boxItem && boxItem.quantity === 1) {
            const { [id]: currentItem, ...restOfIds } = box
            setBox(restOfIds)
            return
        }
        if (!!boxItem && boxItem.quantity > 1) {
            setBox({ ...box, [id]: { ...boxItem, quantity: boxItem.quantity - 1 } })
            return
        }
    }

    const isCreatingOrder = useSelector(
        (state: GlobalAppState) => state.createOrder.isLoading
    );
const isActiveSub = subscription.orders.length < subscription.numberOfWeeks
    return (
        <>
            <div className="row mb-4">
                {
                    weekOrder ? 
                    weekOrder.orderRecipes.map((recipe) => {
                        return (
                            <div className="col-lg-4 mb-4 mb-lg-0" key={recipe.id}>
                                <SelectedRecipeCard recipe={recipe as any}  />
                            </div>
                        );
                    })
                    :
                recipes.map((recipe) => {
                    return (
                        <div className="col-lg-4 mb-4 mb-lg-0" key={recipe.id}>
                            <SelectRecipeCard isActiveSub={isActiveSub} boxItem={(() => box[recipe.id])()} recipe={recipe} addRecipeToBox={addRecipeToBox} removeRecipeFromBox={removeRecipeFromBox} />
                        </div>
                    );
                })
                }

            </div>
           {!weekOrder ? !isActiveSub ? <div/> : (<div className="bg-white p-3 position-fixed d-flex justify-content-between align-items-center w-100 footer border-top">
                <div className="">{`Select ${subscription.numberOfRecipes} recipe${subscription.numberOfRecipes > 1 ? "s" : ''}`}</div>
                <button className="btn btn-primary text-small" onClick={() => dispatch(CreateOrderActions.createOrder({ address: subscription.address, deliveryDate: subscription.deliveryDate, orderRecipes: orderRecipes, weekStartDate: week, phoneNumber: subscription.phoneNumber }))} disabled={numberOfRecipesInBox < subscription.numberOfRecipes || isCreatingOrder}>{isCreatingOrder? "loading...": "Order"}</button>
            </div>):
            (<div className="bg-white p-3 position-fixed d-flex align-items-center w-100 footer border-top">
            <div className="">Week completely planned</div>
        </div>)
            }
            <style jsx>{`
            .footer{
                bottom: 0;
                left:0;
            }
            `}</style>
        </>
    );
};
