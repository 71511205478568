import * as React from 'react'
import { useAuth } from 'use-auth0-hooks'
import { getconfig } from '@goodfood/utils';
import { fetchClient } from "@goodfood/lib";

const config = getconfig()

type Props = {
    children: React.ReactNode
}

export const WithAuth = ({
    children
}: Props) => {
    const { accessToken, isLoading } = useAuth({
        audience: config.audience,
        scope: config.scope
      });
      if (isLoading || !accessToken) {
          return null
      }
      fetchClient.setAuthToken(accessToken)
    return (
        <>
            {children}
        </>
    )
}
