import * as React from "react";
import Head from "next/head";
import { NavBar } from "../navigation";
import classNames from "classnames";
import { useAuth } from "use-auth0-hooks";
import { translate } from "react-i18nify";
import { Footer } from "./Footer";

type Props = {
  title?: string;
  className?: string;
  marginTop?: boolean;
};

export const Layout: React.FunctionComponent<Props> = ({
  children,
  title = "",
  className,
  marginTop = true,
}) => {
  const { isLoading } = useAuth();
  if (isLoading) {
    return <div>{translate("mealHaus.loading")}</div>;
  }
  return (
    <React.Fragment>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <NavBar />
      <div
        className={classNames(className, "flex-grow-1", { "mt-2": marginTop })}
      >
        {children}
      </div>
      <Footer />
    </React.Fragment>
  );
};
