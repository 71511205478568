import * as React from "react";
import { FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";
import Link from "next/link";

export const Footer = () => {
  return (
    <div className="bg-light border-top">
      <div className="d-flex flex-nowrap justify-content-end p-3">
        <div className="">
          <ul className="list-unstyled d-flex m-0">
            <li>
              <a
                href="https://twitter.com/mealhaus"
                className="text-decoration-none ml-2"
                target="__blank"
              >
                <FaTwitter
                  fontSize="1.8rem"
                  className="social-media-icon"
                  color="#1da1f2"
                />
              </a>
            </li>
            <li>
              <a
                href="https://facebook.com/mealhaus.ng"
                className="text-decoration-none ml-2"
                target="__blank"
              >
                <FaFacebookF
                  fontSize="1.8rem"
                  className="social-media-icon"
                  color="#3b5998"
                />
              </a>
            </li>
            <li>
              <a
                href="https://instagram.com/mealhaus.ng"
                className="text-decoration-none ml-2"
                target="__blank"
              >
                <FaInstagram
                  fontSize="1.8rem"
                  className="social-media-icon"
                  color="#cb3842"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
